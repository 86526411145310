<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Расчет эталона</div>
        <div class="tools"></div>
      </div>
      <div class="card-body">
        <excel-references-row
          @loadNewFiles="loadNewFiles"
          :files="files"
          class="my-10"
        />
        <file-references-table @deleteFile="deleteFile" :files="files" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import ExcelReferencesRow from "../../feature/references/ExcelReferencesRow.vue";
import FileReferencesTable from "../../feature/references/tables/FileReferencesTable.vue";

export default {
  components: { ExcelReferencesRow, FileReferencesTable },
  name: "references-list-page",
  setup() {
    const files = ref([]);
    const loadNewFiles = (newFiles) => {
      files.value = files.value.concat(newFiles);
    };

    const deleteFile = (name) => {
      files.value = files.value.filter((file) => file.name !== name);
    };

    return {
      loadNewFiles,
      deleteFile,
      files,
    };
  },
};
</script>

<style>
.btn-accent {
  margin-left: 15px;
}
</style>